<template>
  <div>
    <v-row
      v-if="viewData.id"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="8"
        xl="7"
      >
        <v-card
          outlined
          class="rounded-lg"
          :class="{
            'grey darken-3': $vuetify.theme.dark,
          }"
        >
          <v-card-text>
            <table class="company-info-table">
              <thead>
                <tr>
                  <th class="w-50" />
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    colspan="2"
                    class="text-start"
                  >
                    <v-btn
                      small
                      depressed
                      @click="printInfo()"
                    >
                      <v-icon left>
                        mdil-printer
                      </v-icon>
                      {{ $t('Print') }}
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t('Name Kurdish') }}
                  </td>
                  <td>
                    {{ viewData.name_ckb }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t('Name Arabic') }}
                  </td>
                  <td>
                    {{ viewData.name_ckb }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t('Name English') }}
                  </td>
                  <td>
                    {{ viewData.name_en }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t('Business ID Card') }}
                  </td>
                  <td>
                    {{ viewData.id_card }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t('Owner Type') }}
                  </td>
                  <td>
                    {{ $t(`factory.${viewData.owner_type_name}`) }}
                  </td>
                </tr>
                <tr v-if="viewData.owner_type == 1">
                  <td class="font-weight-bold">
                    {{ $t('Owner') }}
                  </td>
                  <td>
                    {{ viewData.shareholder.username }}
                  </td>
                </tr>
                <tr v-if="viewData.owner_type == 2">
                  <td class="font-weight-bold">
                    {{ $t('Company') }}
                  </td>
                  <td>
                    {{ viewData.company.name }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t('Type') }}
                  </td>
                  <td>
                    {{ $t(viewData.type_name) }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t('Address') }}
                  </td>
                  <td>
                    {{ viewData.address }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t('Activity Type') }}
                  </td>
                  <td>
                    {{ viewData.activity_type }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t('Language') }}
                  </td>
                  <td>
                    {{ viewData.language }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t('Date') }}
                  </td>
                  <td>
                    {{ $_date_format(viewData.input_date) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      viewData: {},
    }
  },

  mounted() {
    this.getData()
  },

  methods: {
    async getData() {
      await axios.get(`factory/factory/${this.$route.params.id}/`).then(res => {
        this.viewData = res.data
      })
    },

    printInfo() {
      let content = {
        content: '',
        css: ''
      }

      content.content = `
        <div style="width: 100%" class="px-6" class="white">
          <table
            cellspacing="0"
            style="width: 100%; border: 1px solid #aaa; border-radius: 2px; border-bottom: 0px"
          >
            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Name')}:</b> ${this.viewData.name}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Company Type')}:</b> ${this.viewData.company_type.name}</td>
            </tr>
           
           <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Governorate')}:</b> ${this.viewData.governorate.name}</td>
              <td class="bottom-border pa-2"><b>${this.$t('First Bank')}:</b> ${this.viewData.first_bank.name}</td>
            </tr>
           
           <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Account Bank')}:</b> ${this.viewData.account_bank.name}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Business Name Date')}:</b> ${this.viewData.name_date}</td>
            </tr>
           
           <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Registration Number')}:</b> ${this.viewData.company_registration_number ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Company Registration Date')}:</b> ${this.$_date_format(this.viewData.company_registration_date)}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Manager')}:</b> ${this.viewData.manager ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Phone')}:</b> ${this.viewData.phone}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Email')}:</b> ${this.viewData.email ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Website')}:</b> ${this.viewData.website ?? ''}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Dos Number In Companies/Slemany')}:</b> ${this.viewData.dos_number_in_co ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Dos Number In Tax Office')}:</b> ${this.viewData.dos_number_in_tax_office ?? ''}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('SL')}:</b> ${this.viewData.sl ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('FCC')}:</b> ${this.viewData.fcc ?? ''}</td>
            </tr>

            <tr>
              <td colspan="2" class="bottom-border pa-2"><b>${this.$t('Activity')}:</b> ${this.viewData.activity ?? ''}</td>
            </tr>
          </table>
        </div>
      `

      content.css = `
        .bottom-border {
          border-bottom: 1px solid #aaa
        }
      `

      this.$_manual_print(content)
    }
  },
}
</script>

<style lang="scss">
.company-info-table tbody > tr > td {
  padding: 0.4rem 0.2rem;
}
</style>